import { helpers } from '@common'
const ENV = helpers.getRuntimeEnv()

const COUNTRY_ID_ID = 1
const COUNTRY_ID_MY = 2
const COUNTRY_ID_PH = 3
const COUNTRY_ID_VN = 4

export const countryIdMap = {
  ID: COUNTRY_ID_ID,
  MY: COUNTRY_ID_MY,
  PH: COUNTRY_ID_PH,
  VN: COUNTRY_ID_VN,
}

export const shopeeAppIds = [
  '73124300',
  '16893884',
  '33642245',
  '99557550',
  '534319401',
]

export const lazadaAppIds = [
  '803822301',
  '719317140',
  '11749400',
  '28063486',
  '40720345',
] //前两个是正式

export const JDAppIds = ['426202122'] //京东appId

export const creditStatusMap = {
  CREDIT_STATUS_APPLIED: 1, //已授信
  CREDIT_STATUS_UNAPPLIED: 2, //未授信
  CREDIT_STATUS_APPLING: 3, //授信审核中
  CREDIT_STATUS_APPLY_FAILED: 4, //授信失败
}

export const newCreditStatusMap = {
  CREDIT_STATUS_APPLIED: 8, //已授信
  CREDIT_STATUS_FROZEN: 10, //风控冻结
  CREDIT_STATUS_UNAPPLIED: 2, //未授信
  CREDIT_STATUS_APPLING: 3, //授信审核中
  CREDIT_STATUS_APPLY_FAILED: 4, //授信失败
}

export const limitCapacityMap = {
  LIMIT_SUFFICIENT: 0, //额度充足
  LIMIT_OUT_CREDIT: 1, //额度不足
  LIMIT_REJECT_BY_RISK: 2, //被风控拒绝
  LIMIT_NO_CREDIT_NEW_USER: 3, //新用户没额度
}

export const bankStatusMap = {
  BANK_STATUS_NOT_NEED: 0, //不需要网银授权
  BANK_STATUS_NEED_APPLY: 1, //需要网银授权
  BANK_STATUS_APPLYING: 2, //授权中
  BANK_STATUS_APPLY_FAILED: 3, //授权失败
  BANK_STATUS_APPLIED: 4, //已通过授权
}

// 下单页优惠券弹窗显示内容控件
export const COUPON_MODAL_FLAG = {
  COUPON: 1, // 显示优惠券内容
  INSTRUCTION: 2, // 显示优惠券使用说明
}

//couponType 优惠券类型 1:站内电商券 2:分期电商券 3:站内利息券  4: 分期券  5: openpay电商券  6：openpay利息券 22: 利率券
export const COUPON_TYPE = {
  COUPON_ECOMMERCE: 1,
  COUPON_FINANCIAL: 2,
  COUPON_INTEREST: 3,
  COUPON_LOAN: 4,
  COUPON_OPENPAY: 5,
  COUPON_OPENPAY_INTEREST: 6,
  COUPON_INTEREST_RATE: 22,
}

export const COUPON_DISCOUNT_TYPE = {
  FULL_REDUCTION: 1,
  DISCOUNT: 2,
}

// 国家手机号开头
export const PHONE_COUNTRY_CODE_MAP = {
  [COUNTRY_ID_ID]: '62',
  [COUNTRY_ID_MY]: '60',
  [COUNTRY_ID_PH]: '63',
  [COUNTRY_ID_VN]: '84',
}

// 首页展示页面类型
export const FIRST_PAGE_TYPE = {
  LOGIN: 1,
  REGISTER: 2,
}

// 首页登录类型
export const FIRST_PAGE_LOGIN_TYPE = {
  SMS: 1,
  ACCOUNT: 2,
}

// 收银台支付方式状态码
export const PAY_METHOD_STATUS = {
  ENABLED: 1, // 启用
  ABNORMAL: 2, // 异常
  DISABLED: 3, // 停用
}

// 安全组件的触发方式
export const SECURITY_VERIFICATION_CALL_METHOD = {
  LOGIN: 1, // 登录
  CAPTCHA: 2, // 获取验证码
}

// 安全组件的bizType
export const SECURITY_VERIFICATION_BIZ_TYPE = {
  LOGIN: 19, // h5 密码登录
  REGISTER: 204, // 短信登录或者注册防刷，不区分mobile、pc
  ADD_ORDER: 13, // h5下单
  PC_LOGIN: 28, // pc密码登录
  PC_ADD_ORDER: 29, // pc下单
  SMS_LOGIN: 5253, // 短信登录
  APP_ADD_ORDER: 55, // app内下单
}

// pc端登录页tab类型
export const PC_NOW_OPERATION_TYPE = {
  LOGIN: 'login',
  REGISTER: 'register',
}

// 重构整合登录后的用户状态
export const AUTH_STATUS = {
  NO_REGISTER: -1, // 未注册
  REGISTER_NO_CREDIT: 0, // 已注册未授信
  CREDIT_STATUS_APPLING: 1, // 审核中
  CREDIT_STATUS_REJECTED: 7, // 授信拒绝
  CREDIT_STATUS_APPLIED: 8, // 授信通过
  CREDIT_STATUS_FROZEN: 10, //风控冻结
}

// 设备信息上报场景值
export const SCENARIO_NUMBER_MAP = {
  LOGIN_PAGE: 1900, // 进入登录页面
  LOGIN: 1003, // 登录成功
  ADD_ORDER: 1014, // 点击下单
  SECURITY_CHECK: 1060, // 下单调用安全组件
  LOGIN_SECURITY_CHECK: 1061, // 登录安全校验
  PAYMENT_PASSWORD: 1075, // 支付密码设备上报
}

// 登录方式
export const LOGIN_METHOD = {
  1: 'account', // 账号密码登录
  2: 'cache', // 缓存登录
  3: 'sms', // 短信验证码登录
  4: 'account', // 短信验证码登录 后端那边的枚举 4 为短信验证码登录
  5: 'token', // token登录
}

// 安全组件滑块配置
export const SLIDER_CONFIG = {
  appName: 'op_web',
  appBrand: 'openpay',
  source: '900',
  reportBaseUrl:
    ENV === 'prod' ? 'https://r.akulaku.net' : 'https://test-r.akulaku.net',
}

// 安全组件刷脸参数配置
export const FACE_CONFIG = {
  appName: 'op_tt_web',
  source: 17,
}
