import { helpers } from '@common'
import { bridge } from '~/common/bridge'
import { getTracker } from '~/common/tracker/tracker'

const { storage } = helpers

export default class Logger {
  constructor(params) {
    //存放每次埋点必须传的基本数据
    this.basicData = params
    this.uid = 'empty'
    this.pageIdMap = {
      login: NEW_PAGE_LOGIN,
      paymentMethod: NEW_PAGE_PAYMENT_RESULT,
      orderDetails: NEW_PAGE_CONFIRM_ORDER,
      register: NEW_PAGE_LOGIN,
      // 'pay-continue': PAGE_PAY_CONTINUE,
      'paymentMethod/paymentCode': NEW_PAGE_PAYMENT_CODE,
      // 'paymentMethod/creditCard': PAGE_PAYMENT_DISPLAY,
      // 'paymentMethod/momo': PAGE_PAYMENT_DISPLAY,
      paymentResult: NEW_PAGE_CHECKOUT,
      // 'applyLimits/1/1': PAGE_PERSONAL_INFO,
      // 'applyLimits/1/2': PAGE_PERSONAL_INFO,
      // 'applyLimits/2/1': PAGE_COMPANY_INFO,
      // 'applyLimits/2/2': PAGE_COMPANY_INFO,
      // 'applyLimits/3/1': PAGE_IDENTITY_INFO,
      // 'applyLimits/3/2': PAGE_IDENTITY_INFO
      'applyLimit-instruction': NEW_PAGE_GUIDE,
    }
  }

  async getSa() {
    return await getTracker()
  }

  setPageConfig = async (param) => {
    const sa = await this.getSa()
    const page_id =
      typeof param.Aku_page_id !== 'undefined'
        ? param.Aku_page_id
        : this.getCurrentPageId()
    const page_name = param.Aku_page_name
    sa.setPageConfig({
      sa: {
        page_id,
        page_name,
      },
    })
  }

  // 替换成 v4 的神策埋点，会自动添加 Aku_ 前缀，不需要前缀的参数放在 noPrefixData 里面
  // 这里不处理参数，是因为下面的 transformParams 会去处理 Aku 前缀
  getCommonParams() {
    return {
      Aku_orderStatus: storage.getSession('Aku_orderStatus'),
      // Aku_systemType：1站内，2站外
      Aku_systemType: bridge.isAkulaku ? 1 : 2,
      noPrefixData: {
        openPayAppID: helpers.URL.getParam('appId'),
        gateway: storage.getSession('gateway'),
        uid: this.uid,
        refNo: helpers.URL.getParam('refNo'),
      },
    }
  }

  // 将之前的参数转化为适合 v4 的
  // 之前的参数存在 Aku_ 开头的 需要去掉这个前缀，库会自动添加
  // 之前不存在的，需要放入 noPrefixData
  transformParams(params) {
    if (!params) return {}
    const newParams = { noPrefixData: {} }
    Object.entries(params).forEach(([key, value]) => {
      if (key.startsWith('Aku_')) {
        newParams[key.replace('Aku_', '')] = value
      } else {
        // 如果之前就已经存在了 noPrefixData，那么直接覆盖
        if (key === 'noPrefixData') {
          newParams.noPrefixData = {
            ...newParams.noPrefixData,
            ...(value || {}),
          }
        } else {
          newParams.noPrefixData[key] = value
        }
      }
    })
    return newParams
  }

  // 主要用于标签埋点的参数处理
  // 返回的是 Object ，标签使用需要用 JSON.stringify 转换
  getExposureParams(params) {
    const commonParams = this.getCommonParams()
    return this.transformParams({ ...commonParams, ...params })
  }

  getCurrentPageId() {
    const { pathname } = window.location
    const page = pathname.replace(/\/open-pay\/(pc\/)?/, '')

    return this.pageIdMap[page]
  }

  // 曝光埋点
  async expose(params) {
    const sa = await this.getSa()
    const commonParams = this.getCommonParams()
    sa.expose({
      sa: this.transformParams({ ...commonParams, ...params }),
    })
  }

  // 保留以前的写法 name,params
  // 但是不特殊处理 name 事件，正常情况下，name 其实就是这里的 params
  // 正常调用只需要传递一个参数就行
  async view(name, params) {
    // const saEvent = typeof name === 'string' ? name : 'Aku_WebViewScreen'
    params = params || name
    const sa = await this.getSa()
    const commonParams = this.getCommonParams()
    const saParams = this.transformParams({ ...commonParams, ...params })
    this.setPageConfig(saParams)
    sa.enter({ sa: saParams })
  }

  // 保留以前的写法 name,params
  // 但是不特殊处理 name 事件，正常情况下，name 其实就是这里的 params
  // 正常调用只需要传递一个参数就行
  async click(name, params) {
    // const saEvent = typeof name === 'string' ? name : 'Aku_WebClick'
    params = params || name
    const sa = await this.getSa()
    const commonParams = this.getCommonParams()
    sa.click({
      sa: this.transformParams({
        ...commonParams,
        ...params,
      }),
    })
  }

  async popView(params) {
    const sa = await this.getSa()
    const commonParams = this.getCommonParams()
    sa.popView({
      sa: this.transformParams({
        ...commonParams,
        ...params,
      }),
    })
  }

  async popClick(params) {
    const sa = await this.getSa()
    const commonParams = this.getCommonParams()
    sa.popClick({
      sa: this.transformParams({ ...commonParams, ...params }),
    })
  }

  async onPageView(opts = {}) {
    const Page_id =
      typeof opts.Aku_page_id !== 'undefined'
        ? opts.Aku_page_id
        : this.getCurrentPageId()
    const sa = await this.getSa()
    const commonParams = this.getCommonParams()
    const saParams = this.transformParams({
      ...this.basicData,
      openpayPayStatus: this.openpayPayStatus,
      openpayRiskStatus: this.openpayRiskStatus,
      openpayRiskReturn: this.openpayRiskReturn,
      uid:
        Page_id === PAGE_LOGIN || Page_id === PAGE_REGISTER
          ? 'empty'
          : this.uid,
      ...commonParams,
      ...opts,
    })
    this.setPageConfig(saParams)
    sa.enter({
      sa: saParams,
    })
  }

  async onPageClick(opts = {}) {
    const { Aku_page_id } = opts
    const sa = await this.getSa()
    const commonParams = this.getCommonParams()
    sa.click({
      sa: this.transformParams({
        ...this.basicData,
        openpayPayStatus: this.openpayPayStatus,
        openpayRiskStatus: this.openpayRiskStatus,
        openpayRiskReturn: this.openpayRiskReturn,
        uid:
          Aku_page_id === PAGE_LOGIN || Aku_page_id === PAGE_REGISTER
            ? 'empty'
            : this.uid,
        ...commonParams,
        ...opts,
      }),
    })
  }

  async onPageInput(opts = {}) {
    const { Aku_page_id } = opts
    const sa = await this.getSa()
    const commonParams = this.getCommonParams()
    const params = this.transformParams({
      ...this.basicData,
      openpayRiskReturn: this.openpayRiskReturn,
      uid:
        Aku_page_id === PAGE_LOGIN || Aku_page_id === PAGE_REGISTER
          ? 'empty'
          : this.uid,
      ...commonParams,
      ...opts,
    })
    // input 事件需要一个全局唯一的 inputKey，之前的相应埋点没有这个参数
    // 这里取 Aku_element_id 来作为这个参数
    sa.input({
      sa: {
        ...params,
        inputKey: params.element_id || params.element_name,
      },
    })
  }

  async onPageError(opts = {}) {
    // 和袋袋沟通后，去掉这个埋点
    // Aku_WebErrorType 无这个埋点事件
    return
    // const { Aku_page_id } = opts
    // const sa = await this.getSa()
    // const commonParams = this.getCommonParams()
    // sa.track('Aku_WebErrorType', {
    //   ...this.basicData,
    //   uid:
    //     Aku_page_id === PAGE_LOGIN || Aku_page_id === PAGE_REGISTER
    //       ? 'empty'
    //       : this.uid,
    //   ...commonParams,
    //   ...opts,
    // })
  }

  setUid(uid) {
    this.uid = uid
  }

  async login(uid) {
    const sa = await this.getSa()
    sa.login(uid)
  }

  setOpenpayPayStatus(status) {
    this.openpayPayStatus = status
  }

  setOpenpayRiskStatus(status) {
    this.openpayRiskStatus = status
  }

  setOpenpayRiskReturn(status) {
    this.openpayRiskReturn = status
  }

  static create(params) {
    if (!Logger.instance) {
      Logger.instance = new Logger(params)
    }
    return Logger.instance
  }

  /**
   * @returns {Logger}
   */
  static getInstance() {
    if (!Logger.instance) {
      throw new Error(
        "Logger has not been created yet, please use Logger.create() before getting it's instance",
      )
    }

    return Logger.instance
  }
}

/* pageClick */
export const CLICK_SEND_CODE = 1 //发送验证码
export const ADD_ORDER = 2 //点击确认下的那
export const ADD_MATIRIAL = 3 //补充材料
export const CANCEL_ADD_MATIRIAL = 4 //取消补充材料
export const SUBMIT_NET_BANK_ACCOUNT = 5 //提交网银账号
export const APPLY_LIMIT = 6 //申请分期支付
export const CONNECT_E_ACCOUNT = 7 //链接电商账号
export const CLICK_LOGIN = 8 //点击登录
export const BACK_TO_MERCHANT = 10 //返回商家
export const CONNECT_BANCK = 11 //链接银行，申请0首付
export const SMS_SUBMIT = 12 //短信验证码提交
export const CLICK_GRAPHIC_SUBMIT = 13 //图形验证码提交
export const INPUT_PWD_SUBMIT = 14 //输入密码提交
export const SET_NEW_PWD_SUBMIT = 15 //设置密码提交
export const CLICK_CLOSE_GRAPHIC_DIALOG = 16 //关闭图形验证码弹窗
export const CLOSE_INPUT_PWD_DIALOG = 17 //关闭输入密码弹窗
export const CLOSE_SET_PWD_DIALOG = 18 //关闭设置密码
export const CHOOSE_PAYMENT_RATE = 19 //选择首付比例
export const CHOOSE_PERIOD = 20 //选择分期数
export const CONTINUE_PAYMENT = 21 //注册成功，继续支付
export const CLICK_TERMS = 22 //注册页点击条款
export const CLICK_REGISTER = 23 //注册页点击注册
export const CONTINUE_APPLY_LIMIT = 24 //注册成功页点击立即授信
export const CLICK_NET_BANK_LOGIN = 25 //网银登录点击
export const CLICK_SUBMIT_RISK_SMS = 26 //风控短信验证码点击提交
export const CLICK_LOGINPAGE_REGISTER = 27 //登录页点击去注册
export const CLICK_CANCEL_ORDER = 28 //取消订单
export const CLICK_PAYMENT_PWD_SUBMIT = 29 //下单页密码提交
export const CLICK_PAYMENT_PWD_CANCEL = 30 //下单页取消密码输入框
export const CLICK_PAYMENT_LOGOUT = 31 //登出
export const CLICK_REGISTER_PWD_SUBMIT = 32 //注册页密码提交
export const CLICK_DOWNLOAD_LINK_SMS = 33 //发送aku下载链接短信
export const CLICK_ADD_COUPON = 34 //点击新增优惠码
export const CLICK_SAVE_COUPON = 35 //点击保存优惠码
export const CLICK_CANCEL_COUPON = 36 //点击取消优惠码
export const CLICK_REMOVE_COUPON = 37 //点击移除优惠码
export const CLICK_REMOVE_COUPON_POPUP_CONFIRM = 38 //点击移除优惠码弹窗确认
export const CLICK_REMOVE_COUPON_POPUP_CANCEL = 39 //点击移除优惠码弹窗取消
export const CLICK_CONTINUE_PAY = 40 //继续支付页面点击支付
export const CLICK_RESELECT_PAYMENT = 41 //继续支付页面点击返回重选支付方案
export const CLICK_USE_SMS_VERIFY = 42 //密码弹框-点击切换短信验证
export const CLICK_SUBMIT_PASSWORD_VERIFY = 43 //密码弹框-点击确认
export const CLICK_CLOSE_PASSWORD_VERIFY = 44 //密码弹框-点击关闭
export const CLICK_USE_PASSOWRD_VERIFY = 45 //短信弹框-点击切换短信验证
export const CLICK_SUBMIT_SMS_VERIFY = 46 //短信弹框-点击确认
export const CLICK_CLOSE_SMS_VERIFY = 47 //短信弹框-点击关闭
export const CLICK_FORGOT_PASSWORD = 48 //首页点击忘记密码
export const CLICK_BANNER_ORDER_CONFIRM = 49 //确认订单页点击banner
export const CLICK_BANNER_PAYMENT_SUCCESS = 50 //支付成功页点击banner
export const CLICK_SHOW_PAY_RATE_POPUP = 51 //点击显示首付比例弹窗
export const CLICK_CLOSE_PAY_RATE_POPUP = 52 //关闭首付比例弹窗
export const CLICK_CONFIRM_PAY_RATE = 53 //点击首付比例确认

/* pageClick end */

/* openpay value */
export const STATUS_SMS_PASS = 1 //短信验证码通过
export const STATUS_SMS_FAILED = 2 //短信验证码不通过
export const STATUS_GRAPHIC_PASS = 3 //图形验证码通过
export const STATUS_GRAPHIC_FAILED = 4 //图形验证码不通过
export const STATUS_SET_NEW_PWD_PASS = 5 //设置密码通过
export const STATUS_SET_NEW_PWD_FAILED = 6 //设置密码不通过
export const STATUS_REGISTER_SUCCESS = 7 //注册通过
export const STATUS_REGISTER_FAILED = 8 //注册不通过
/* openpay value end*/

/* pageInput */
export const PHONE_NUMBER_INPUT = 1 //手机号输入
export const CAPTCHA_INPUT = 2 //验证码输入
export const NETBANK_USER_ID_INPUT = 3 //补充网银资料userID输入
export const NETBACK_PASSWORD_INPUT = 4 //补充网银密码输入
export const NAME_INPUT = 5 //授信填写姓名
export const SELECT_OCCUPATION = 6 //选择职业
export const CONTACT_NAME_INPUT = 7 //联系人姓名
export const SELECT_RELATIONSHIP = 8 //选择联系人关系
export const CONTACT_PHONE_NUMBER_INPUT = 9 //填写联系人手机号码
export const BIND_FACEBOOK = 10 //绑定FB
export const BIND_GMAIL = 11 //绑定Gmail
export const BIND_YAHOO = 12 //绑定雅虎
export const CAMPANY_NAME_INPUT = 13 //填写公司名称
export const SELECT_WORK_YEAR = 14 //选择工作年限
export const SELECT_INCOME = 15 //选择月薪
export const OFFICE_PHONE_NUMBER_INPUT = 16 //办公室电话
export const SELECT_SKILL = 17 //选择职业技能
export const SELECT_HOME_LOCATION = 18 //选择住宅地址
export const SELECT_OFFICE_LOCATION = 19 //选择公司地址
export const IDENTITY_NUMBER_INPUT = 20 //身份证号输入
export const E_ACCOUNT_INPUT = 21 //输入电商账号
export const E_PASSWORD_INPUT = 22 //输入电商密码
export const REGISTER_PHONE_INPUT = 23 //注册页输入手机号
export const REGISTER_PWD_INPUT = 24 //注册页输入密码
export const REGISTER_CONFIMR_PWD_INPUT = 25 //注册页输入确认密码
export const REGISTER_CAPTCHA_INPUT = 26 //注册页输入验证码
export const LOGIN_PASSWORD_INPUT = 27 //登录页输入密码
export const LOGIN_GRAPHIC_CPATCHA_INPUT = 28 //注册页输入图形验证码
export const NET_BANK_ACCOUNT_INPUT = 29 //网银登录输入账号
export const NET_BANK_PASSWORD_INPUT = 30 //网银登录输入密码
export const RISK_SMS_INPUT = 31 //风控短信验证码输入
export const PAYMENT_PASSWORD_INPUT = 32 //下单页输入密码
export const COUPON_CODE_INPUT = 33 //输入优惠码
/* pageInput end */

export const LOG_BEGIN = 'begin' //开始
export const LOG_END = 'end' //结束

/* pageId */
export const PAGE_LOGIN = 1 //登录页
export const PAGE_PERIOD_PAY = 2 //分期详情页
export const PAGE_FULL_PAY = 3 //全款支付页
export const PAGE_CHOOSE_PAYMENT_METHOD = 4 //支付方式选择
export const PAGE_PAYMENT_DISPLAY = 5 //支付展示页
export const PAGE_PAYMENT_ACCOMPLISHED = 6 //支付完成页
export const PAGE_PERSONAL_INFO = 10 //个人信息填写页
export const PAGE_COMPANY_INFO = 11 //公司信息填写页
export const PAGE_IDENTITY_INFO = 12 //身份证信息填写页
export const PAGE_CONNECT_EC = 13 //连接电商页
export const PAGE_REGISTER = 14 //注册页
export const PAGE_REGISTER_SUCCESS = 15 //注册成功页
export const PAGE_NET_BANK_AUTH = 16 //网银授权页（v4上mixture）
export const PAGE_NET_BANK_AUTH_DETAIL = 17 //网银授权详情
export const PAGE_NET_BANK_LOGIN = 18 //网银登录
export const PAGE_INSTALLMENT_ABLE = 19 //分期详情页-可分期
export const PAGE_NOT_APPLY_NOT_INSTALLMENT = 20 //分期详情页-未授信不可分期
export const PAGE_APPLIED_NOT_INSTALLMENT = 21 //分期详情页-已授信不可分期
export const PAGE_ORDER_CONFIRM = 22 //确认下单页
export const PAGE_PAY_CONTINUE = 27 //继续支付

/* errorType */
export const ERROR_LOGIN_FAIL = 1 //登录失败
export const ERROR_REGISTER_FAIL = 2 //注册失败
export const ERROR_PAYMENT_FAIL = 3 //支付失败

// pageId new
export const NEW_PAGE_LOGIN = 'openpay01' // 登陆页
export const NEW_PAGE_GUIDE = 'openpay02' // 全款用户引导页
export const NEW_PAGE_CONFIRM_ORDER = 'openpay03' // 确认下单页
export const NEW_PAGE_PAYMENT_RESULT = 'openpay04' // 支付结果页
export const NEW_PAGE_CHECKOUT = 'openpay05' // 收银台页
export const NEW_PAGE_PAYMENT_CODE = 'openpay06' // 支付码页面
