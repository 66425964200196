import { useRef, useLayoutEffect, useCallback } from 'react'
/**
 * useCallback有太多的依赖问题，useEvent用于避免依赖问题
 */
export default function useEvent<T extends (...args: any[]) => any>(
  handler: T,
): (...args: Parameters<T>) => ReturnType<T> {
  const handlerRef = useRef<{ fn: T }>({ fn: handler })

  // 视图渲染完成后更新`handlerRef.current`指向
  useLayoutEffect(() => {
    handlerRef.current.fn = handler
  })

  // 用useCallback包裹，使得render时返回的函数引用一致
  return useCallback((...args: Parameters<T>): ReturnType<T> => {
    const fn = handlerRef.current.fn
    return fn?.(...args)
  }, [])
}
